import { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faXmark, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import './LinkItem.css';
import { linkFinder, makeLookupTreeFromItems, textHasMatch } from '../biz/linkFinder';

const findLinks = (item, itemLookupTree, allItems) => {
  const foundLinks = linkFinder(itemLookupTree, item.text, item.id).links;
  const intrinsic = foundLinks.map(l => ({ id: l.id, name: l.name, type: allItems.find(i => i.id === l.id).type }));
  const extrinsic = [];
  const thisLookupTree = makeLookupTreeFromItems([item]);

  for (let otherItem of allItems) {
    if (item.id !== otherItem.id) {
      if (textHasMatch(thisLookupTree, otherItem.text)) {
        extrinsic.push({ id: otherItem.id, name: otherItem.name, type: otherItem.type });
      }
    }
  }
  return {intrinsic, extrinsic};
};

function LinkItem({item, focus, itemLookupTree, link, addLink, removeLink, setParent, close, back, allItems}) {
  const [implicitLinks] = useState(findLinks(item, itemLookupTree, allItems));
  const [showExplicitLinkRow, setShowExplicitLinkRow] = useState(false);
  
  const tryLinking = useCallback((e) => {
    if (e.target && e.target.dataset.id) {
      link(e.target.dataset.id);
    }
  }, [link]);

  const openAll = useCallback(() => {
    for (let itemId of item.links) {
      link(itemId);
    }
    for (let itemLink of implicitLinks.intrinsic) {
      link(itemLink.id);
    }
    for (let itemLink of implicitLinks.extrinsic) {
      link(itemLink.id);
    }
  }, [implicitLinks, link, item]);

  const addLinkCallback = useCallback((e) => {
    addLink(item.id, e.target.value);
  }, [addLink, item]);

  return <>
    <div className="formrow"><span className="name">{item.name}</span></div>
    <div className="links">
      <div className="formrow">
        <label>Parent:</label>
        <select className="linkselect" onChange={(e) => setParent(item.id, e.target.value)} value={item.parent||""}>
          <option value="">None</option>
          { allItems.filter(i => i.id !== item.id && i.type === item.type).map(item => <option key={item.id} className={item.type} value={item.id}>{item.name}</option>) }
        </select>
      </div>
      {
        item.links.length > 0 &&
        <>
          <div className="formrow"><div className="linkcategory">Explicit links</div></div>
          <div className="formrow">
            <ul>
              { item.links.map(linkId => allItems.find(i => i.id === linkId)).map(linkItem =>
                  linkItem && <li key={linkItem.id} className={linkItem.type} onClick={tryLinking} data-id={linkItem.id}>
                    <span className="removeLink" onClick={() => removeLink(item.id, linkItem.id)}><FontAwesomeIcon icon={faXmark} /></span>
                    {linkItem.name}
                  </li>
                )
              }
            </ul>
          </div>
        </>
      }
      {
        implicitLinks.intrinsic.filter(linkItem => !item.links.includes(linkItem.id)).length > 0 &&
        <>
          <div className="formrow"><div className="linkcategory">Intrinsic references</div></div>
          <div className="formrow">
            <ul>
              { implicitLinks.intrinsic.filter(linkItem => !item.links.includes(linkItem.id)).map(linkItem =>
                  <li key={linkItem.id} className={linkItem.type} onClick={tryLinking} data-id={linkItem.id}>
                    <span className="pinLink" onClick={() => addLink(item.id, linkItem.id)}><FontAwesomeIcon icon={faThumbtack} /></span>
                    {linkItem.name}
                  </li>
                )
              }
            </ul>
          </div>
        </>
      }
      {
      item.type !== "quest" && item.type !== "logentry" && implicitLinks.extrinsic.filter(linkItem => !item.links.includes(linkItem.id)).length > 0 &&
      <>
        <div className="formrow"><div className="linkcategory">Extrinsic references</div></div>
        <div className="formrow">
          <ul>
            { implicitLinks.extrinsic.filter(linkItem => !item.links.includes(linkItem.id)).map(linkItem =>
                <li key={linkItem.id} className={linkItem.type} onClick={tryLinking} data-id={linkItem.id}>
                  <span className="pinLink" onClick={() => addLink(item.id, linkItem.id)}><FontAwesomeIcon icon={faThumbtack} /></span>
                  {linkItem.name}
                </li>
              )
            }
          </ul>
        </div>
        </>
      }
    </div>
    <div className="formrow grow"></div>
    {
      showExplicitLinkRow && <div className="formrow">
        <label>Link to:</label>
        <select className="linkselect" onChange={addLinkCallback} value="">
          <option hidden></option>
          { allItems.filter(i => i.id !== item.id && !i.archived && !item.links.includes(i.id)).map(item => <option key={item.id} className={item.type} value={item.id}>{item.name}</option>) }
        </select>
      </div>
    }
    <div className="buttonrow reverse">
      <div className="buttoncontainer">
        { !showExplicitLinkRow && <button onClick={() => setShowExplicitLinkRow(true)}>Add links</button> }
        <button onClick={openAll}>Open all</button>
        <button onClick={close}>{focus ? "Close focus" : "Close"}</button>
      </div>
      <div className="buttoncontainer grow">
        <button onClick={back}><FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon>Back</button>
      </div>
    </div>
  </>;
}

export default LinkItem;