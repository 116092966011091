import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMaximize, faMinimize } from '@fortawesome/free-solid-svg-icons';
import './SideList.css';
import SideListNode from './SideListNode';

function SideList({extraClass, heading, isFocused, addClick, selectClick, items, showProperty, focus}) {
  return <div className={"sidelist " + extraClass}>
    <div className="innercontainer">
      <div className="listheader" onClick={focus}><span>{heading}</span><span className="icon"><FontAwesomeIcon icon={isFocused ? faMinimize : faMaximize} /></span><div className="listadd" onClick={(e) => { addClick(); e.stopPropagation(); }}>+</div></div>
      <div className="itemcontainer">
        <ul>
          { items.filter(item => !item.parent || item.starred || item.archived)
              .map(item => <SideListNode key={item.id} items={items} item={item} selectClick={selectClick} showProperty={showProperty} />) }
        </ul>
      </div>
    </div>
  </div>;
}

export default SideList;