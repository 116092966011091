import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import './AliasItem.css';

function AliasItem({item, focus, addAlias, removeAlias, close, back}) {
  const [showAddAliasRow, setShowAddAliasRow] = useState(false);
  const [newAlias, setNewAlias] = useState("");
  
  return <>
    <div className="formrow"><span className="name">{item.name}</span></div>
    <div className="aliases">
      <div className="formrow"><div className="aliasheading">More name aliases for wiki-like linking:</div></div>
      <div className="formrow">
        <ul>
          { item.aliases.map((alias, index) =>
              <li key={index} data-id={index}>
                <span className="removeAlias" onClick={() => removeAlias(item.id, index)}><FontAwesomeIcon icon={faXmark} /></span>
                {alias}
              </li>
            )
          }
        </ul>
      </div>
    </div>
    <div className="formrow grow"></div>
    {
      showAddAliasRow && <div className="formrow">
        <input className="grow newalias" type="text" value={newAlias} onChange={(e) => setNewAlias(e.target.value)}></input>
        <button onClick={() => { addAlias(item.id, newAlias); setNewAlias(""); setShowAddAliasRow(false); }}>Add alias</button>
      </div>
    }
    <div className="buttonrow reverse">
      <div className="buttoncontainer">
        { !showAddAliasRow && <button onClick={() => setShowAddAliasRow(true)}>Add alias</button> }
        <button onClick={close}>{focus ? "Close focus" : "Close"}</button>
      </div>
      <div className="buttoncontainer grow">
        <button onClick={back}><FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon>Back</button>
      </div>
    </div>
  </>;
}

export default AliasItem;