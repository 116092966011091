import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCaretLeft, faSquareCaretRight } from '@fortawesome/free-regular-svg-icons';
import { faExpand as faMaximize, faCompress as faMinimize } from '@fortawesome/free-solid-svg-icons';
import './ItemBox.css';
import EditItem from './EditItem';
import ViewItem from './ViewItem';
import LinkItem from './LinkItem';
import { isNewItemId } from '../biz/itemUtil';
import AliasItem from './AliasItem';

const headings = {
  'quest': 'Quest',
  'personororg': 'Person or Organisation',
  'miscitem': 'Miscellaneous item',
  'place': 'Place',
  'logentry': 'Log entry'
};

const headingPrefixes = {
  editing: 'Editing: ',
  linking: 'Linking: ',
  aliasing: 'Aliases: ',
  viewing: ''
};

function ItemBox({item, itemLookupTree, cancel, save, setEditProtection, deleteItem, toggleStarItem, toggleArchiveItem, link, addLink, removeLink, setParent, addAlias, removeAlias, uploadImage, imageUrl, zoomImage, flash, allItems}) {
  const [state, setState] = useState("viewing");
  const [expanded, setExpanded] = useState(false);
  const [lastFlash, setLastFlash] = useState(-1);
  const [focus, setFocus] = useState(false);
  
  useEffect(() => {
    if (isNewItemId(item.id)) {
      setState("editing");
      setEditProtection(item.id, true);
    } else {
      setState("viewing");
    }
    
    setExpanded(false);
  }, [item.id, setEditProtection])

  const shouldFlash = (lastFlash !== flash);
  if (shouldFlash) {
    const newFlash = flash;
    setTimeout(() => setLastFlash(newFlash), 300);
  }

  return <div className={"outerfocus " + (focus ? "focusitem" : "nofocusitem") + (expanded ? " expanded" : "")}>
    <div className="innerfocus">
      <div className={"itembox " + item.type + " " + (item.archived ? "archived" : "") + " " + (shouldFlash ? "flash" : "")}>
        <div className="innercontainer">
          <div className="formrow">
            <span className="type">{headingPrefixes[state] + headings[item.type]}</span>
            <div className="focus" title={focus ? "Unfocus" : "Focus"} onClick={() => setFocus(!focus)}><FontAwesomeIcon icon={focus ? faMinimize : faMaximize}></FontAwesomeIcon></div>
            {!focus && <div className="resize" title={expanded ? "Narrow" : "Wide"} onClick={() => setExpanded(!expanded)}><FontAwesomeIcon icon={expanded ? faSquareCaretLeft : faSquareCaretRight}></FontAwesomeIcon></div>}
          </div>
          {
            {
              "editing": () => <EditItem item={item} cancel={() => { setState("viewing"); setEditProtection(item.id, false); if (isNewItemId(item.id)) { cancel(item.id); } }} uploadImage={uploadImage} save={(item) => { setState("viewing"); setEditProtection(item.id, false); save(item); }} toggleFocus={() => setFocus(!focus)} />,
              "viewing": () => <ViewItem item={item} focus={focus} itemLookupTree={itemLookupTree} close={() => { if (focus) { setFocus(false); return; } cancel(item.id); }} edit={() => { setState("editing"); setEditProtection(item.id, true); }} editLinks={() => setState("linking")} editAliases={() => setState("aliasing")} deleteItem={deleteItem} toggleArchiveItem={toggleArchiveItem} toggleStarItem={toggleStarItem} link={link} imageUrl={imageUrl} zoomImage={zoomImage} />,
              "linking": () => <LinkItem item={item} focus={focus} itemLookupTree={itemLookupTree} close={() => { if (focus) { setFocus(false); return; } cancel(item.id); }} back={() => setState("viewing")} link={link} addLink={addLink} removeLink={removeLink} setParent={setParent} allItems={allItems} />,
              "aliasing": () => <AliasItem item={item} focus={focus} close={() => { if (focus) { setFocus(false); return; } cancel(item.id); }} back={() => setState("viewing")} addAlias={addAlias} removeAlias={removeAlias} />
            }[state]()
          }
        </div>
      </div>
    </div>
  </div>;
}

export default ItemBox;